import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiPartner {
      partner {
        id
        image {
          localFile {
            url
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`

const Partner = () => {
  const data = useStaticQuery(query)
  const {
    strapiPartner: { partner },
  } = data

  return (
    <div className="partner-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="row align-items-center">
          {partner.map(item => {
            const {
              image: {
                localFile: {
                  url: partnerImage
                },
              },
            } = item
            return (
              <div className="col-lg-2 col-6 col-sm-4 col-md-4" key={item.id}>
                <div className="single-partner-item">
                  <img src={partnerImage} alt="Partner" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Partner
