import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import AboutUsContent from "../components/AboutUs/AboutUsContent"
import WhyChooseUs from "../components/AboutUs/WhyChooseUs"
import HowItWork from "../components/AboutUs/HowItWork"
import TeamMembers from "../components/AboutUs/TeamMembers"
import Testimonials from "../components/AboutUs/Testimonials"
import Partner from "../components/AboutUs/Partner"
import Seo from "../components/App/Seo"
import { graphql } from "gatsby"

const AboutUs = ({ data }) => {
  const { metaTags } = data
  const { metaImage } = metaTags

  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === "about-us"
  })[0]
  return (
    <Layout>
      <Seo
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
        url={"about-us"}
      />
      <Navbar />
      <PageBanner
        pageTitle="About Us"
        homePageText="Home"
        homePageUrl="/"
        activePageText="About Us"
      />
      <AboutUsContent />
      <WhyChooseUs />
      <HowItWork />
      <TeamMembers />
      <Testimonials />
      <Partner />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetPageDetails {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        id
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
  }
`

export default AboutUs
