import React from "react"
// import Image from "gatsby-image"

import starIcon from "../../assets/images/star-icon.png"
import shape1 from "../../assets/images/shape/circle-shape1.png"

import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { graphql, Link, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiAboutUs {
      title
      subTitle
      longDesc
      goals {
        title
        longDesc
        btnLink
        btnLinkText
      }
      image {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    strapiFunFacts {
      funFacts {
        shortDesc
        header
        icon {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`

const AboutUsContent = () => {
  const {
    strapiAboutUs: {
      title,
      subTitle,
      longDesc,
      goals,
      image: {
        localFile: {
          childImageSharp: {
            original: { src: aboutImage },
          }
        },
      },
    },
    strapiFunFacts: { funFacts },
  } = useStaticQuery(query)

  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={aboutImage} alt="feature" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={starIcon} alt="banner" />
                  {title}
                </span>
                <h2>{subTitle}</h2>
                <ReactMarkdown
                  children={longDesc}
                  rehypePlugins={[rehypeRaw]}
                />
                <ul className="features-list">
                  {funFacts?.map((funFact, index) => {
                    const {
                      header,
                      shortDesc,
                      icon: {
                        localFile: {
                          childImageSharp: {
                            original: { src: imageSrc },
                          }
                        },
                      },
                    } = funFact
                    return (
                      <li key={index}>
                        <img src={imageSrc} alt="FunIcon" />
                        <h3>{header}</h3>
                        <p>{shortDesc}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row">
            {goals?.map((goal, index) => {
              const { title, longDesc, btnLink, btnLinkText } = goal
              return (
                <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                  <div className="about-text">
                    <h3>{title}</h3>
                    <ReactMarkdown
                      children={longDesc}
                      rehypePlugins={[rehypeRaw]}
                    />
                    {btnLink ? (
                      <Link to={btnLink} className="default-btn">
                        <i className="flaticon-web"></i>
                        {btnLinkText} <span></span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>
    </section>
  )
}

export default AboutUsContent
