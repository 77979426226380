import React from "react"
// import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"

const query = graphql`
  {
    strapiTeamStyle2 {
      shortDesc
      title
      teamCard {
        id
        name
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        designation
        socialLinks {
          id
          icon
          link
        }
      }
    }
  }
`

const TeamMembers = () => {
  const data = useStaticQuery(query)
  const {
    strapiTeamStyle2: { shortDesc, title, teamCard },
  } = data

  return (
    <section className="scientist-area  bg-color pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="startIcon" />
            {shortDesc}
          </span>
          <h2>{title}</h2>
        </div>

        <div className="row">
          {teamCard.map((card, index) => {
            const {
              name,
              designation,
              // socialLinks,
              image: {
                localFile: {
                  childImageSharp: {
                    original: { src: teamMemberImage },
                  },
                },
              },
            } = card
            return (
              <div className="col-lg-3 col-sm-6" key={index}>
                <div className="single-scientist-box">
                  <div className="image">
                    <img src={teamMemberImage} alt="Team Member" />
                  </div>
                  <div className="content">
                    <h3>{name}</h3>
                    <span>{designation}</span>

                    {/* <ul className="social">
                      {socialLinks &&
                        socialLinks.map(item => {
                          return (
                            <li key={item.id}>
                              <Link to={item.link} className="d-block">
                                <i className={item.icon}></i>
                              </Link>
                            </li>
                          )
                        })}
                    </ul> */}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TeamMembers
