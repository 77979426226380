import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiWhyChooseUs {
      title
      subTitle
      shortText
      whyChooseUs {
        title
        shortText
        number
        id
      }
      image {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`

const WhyChooseUs = () => {
  const data = useStaticQuery(query)
  const {
    strapiWhyChooseUs: {
      subTitle,
      title,
      shortText,
      whyChooseUs,
      image: {
        localFile: {
          childImageSharp: {
            original: { src: whyChooseUsImage },
          },
        },
      },
    },
  } = data

  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={starIcon} alt="banner" />
                {subTitle}
              </span>
              <h2>{title}</h2>
              <p>{shortText}</p>
              <div className="inner-box">
                {whyChooseUs.map((list, index) => {
                  const { title, number, shortText } = list
                  return (
                    <div className="single-item" key={index}>
                      <div className="count-box">{number}</div>
                      <h3>{title}</h3>
                      <p>{shortText}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={whyChooseUsImage} alt="Why Choose Us" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
